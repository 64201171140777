<template>
  <div>
    <div class="form-group">
      <label for="jkopayEnable" class="d-block">啟用街口支付</label>
      <CSwitch id="jkopayEnable" size="sm" color="success" :checked.sync="MerchantData['jkopay'].Enable" />
    </div>
    <template v-if="MerchantData['jkopay'].Enable">
      <CInput id="jkopayMerID" v-model="MerchantData['jkopay'].MerchantID" size="sm" type="text">
        <template #label>
          <label for="jkopayMerID" class="d-block">Store ID</label>
        </template>
      </CInput>
      <CInput id="jkopayAppKey" v-model="MerchantData['jkopay'].AppKey" size="sm" type="text">
        <template #label>
          <label for="jkopayAppKey" class="d-block">API Key</label>
        </template>
      </CInput>
      <CInput id="jkopayAccount" v-model="MerchantData['jkopay'].SecureKey" size="sm" type="text">
        <template #label>
          <label for="jkopayAccount" class="d-block">Secret Key</label>
        </template>
      </CInput>
      <div class="form-group">
        <label for="jkopayTestMode" class="d-block">{{ $t('MerchantData.TestMode') }}</label>
        <CSwitch id="jkopayTestMode" size="sm" color="success" :checked.sync="MerchantData['jkopay'].TestMode" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "JKOPAY",
  props: {
    MerchantData: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
