<template>
  <div>
    <div class="form-group">
      <label for="focasEnable" class="d-block">啟用財金資訊付款</label>
      <CSwitch id="focasEnable" size="sm" color="success" :checked.sync="MerchantData['focaspay'].Enable" />
    </div>
    <template v-if="MerchantData['focaspay'].Enable">
      <CInput id="focasMerID" v-model="MerchantData['focaspay'].MerchantID" size="sm" type="text">
        <template #label>
          <label for="focasMerID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
        </template>
      </CInput>
      <CInput id="focasAccount" v-model="MerchantData['focaspay'].Account" size="sm" type="text">
        <template #label>
          <label for="focasAccount" class="d-block">{{ $t('MerchantData.Account') }}</label>
        </template>
      </CInput>
      <CInput id="focasKeyFile" v-model="MerchantData['focaspay'].HashKey" size="sm" type="text">
        <template #label>
          <label for="focasKeyFile" class="d-block">{{ $t('MerchantData.TerminalID') }}</label>
        </template>
      </CInput>
      <CInput id="focasMerchantName" v-model="MerchantData['focaspay'].ProjectName" size="sm" type="text">
        <template #label>
          <label for="focasMerchantName" class="d-block">{{ $t('MerchantData.MerchantName') }}</label>
        </template>
      </CInput>
    </template>
  </div>
</template>

<script>
export default {
  name: "FOCASPAY",
  props: {
    MerchantData: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
