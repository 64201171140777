<template>
  <div>
    <div class="form-group">
      <label for="ecpayEnable" class="d-block">啟用綠界付款</label>
      <CSwitch id="ecpayEnable" size="sm" color="success" :checked.sync="MerchantData['ecpay'].Enable" />
    </div>
    <template v-if="MerchantData['ecpay'].Enable">
      <CInput id="ecpayPaymentMerchantID" v-model="MerchantData['ecpay'].MerchantID" size="sm" type="text">
        <template #label>
          <label for="ecpayPaymentMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
        </template>
      </CInput>
      <CInput id="ecpayPaymentHashKey" v-model="MerchantData['ecpay'].HashKey" size="sm" type="text">
        <template #label>
          <label for="ecpayPaymentHashKey" class="d-block">{{ $t('MerchantData.HashKey') }}</label>
        </template>
      </CInput>
      <CInput id="ecpayPaymentHashIV" v-model="MerchantData['ecpay'].HashIV" size="sm" type="text">
        <template #label>
          <label for="ecpayPaymentHashIV" class="d-block">{{ $t('MerchantData.HashIV') }}</label>
        </template>
      </CInput>
      <div class="form-group">
        <label for="ecpayTestMode" class="d-block">{{ $t('MerchantData.TestMode') }}</label>
        <CSwitch id="ecpayTestMode" size="sm" color="success" :checked.sync="MerchantData['ecpay'].TestMode" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ECPAY",
  props: {
    MerchantData: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
