<template>
  <CAlert color="secondary" :fade="false">
    為提供迅速的導入服務及順暢的結帳流程，請使用TapPay接入永豐銀行或豐收款支付系統。<br>
    若您仍需要直接與永豐銀行或豐收款系統接入，請<router-link class="text-info" to="/feedback">與我們聯絡</router-link>或加入<a class="text-success" href="https://page.line.me/umeans">LINE官方帳號</a>協助服務或洽談串接事宜。
  </CAlert>
</template>

<script>
export default {
  name: "SINOPACPAY"
}
</script>

<style scoped>

</style>
