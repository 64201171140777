<template>
  <div>
    <div class="form-group">
      <label for="newebpayEnable" class="d-block">啟用藍新金流</label>
      <CSwitch id="newebpayEnable" size="sm" color="success" :checked.sync="MerchantData['newebpay'].Enable" />
    </div>
    <template v-if="MerchantData['newebpay'].Enable">
      <CInput id="newebpayMerchantID" v-model="MerchantData['newebpay'].MerchantID" size="sm" type="text">
        <template #label>
          <label for="newebpayMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
        </template>
      </CInput>
      <CInput id="newebpayHashKey" v-model="MerchantData['newebpay'].HashKey" size="sm" type="text">
        <template #label>
          <label for="newebpayHashKey" class="d-block">{{ $t('MerchantData.HashKey') }}</label>
        </template>
      </CInput>
      <CInput id="newebpayHashIV" v-model="MerchantData['newebpay'].HashIV" size="sm" type="text">
        <template #label>
          <label for="newebpayHashIV" class="d-block">{{ $t('MerchantData.HashIV') }}</label>
        </template>
      </CInput>
      <div class="form-group">
        <label for="newebpayTestMode" class="d-block">{{ $t('MerchantData.TestMode') }}</label>
        <CSwitch id="newebpayTestMode" size="sm" color="success" :checked.sync="MerchantData['newebpay'].TestMode" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "NEWEBPAY",
  props: {
    MerchantData: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
