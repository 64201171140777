<template>
  <div>
    <CAlert color="secondary" :fade="false">
      為提供迅速的導入服務及順暢的結帳流程，建議使用TapPay接入台新銀行支付系統。<br>
      若您仍需要直接與台新銀行系統接入，請<router-link class="text-info" to="/feedback">與我們聯絡</router-link>或加入<a class="text-success" href="https://page.line.me/umeans">LINE官方帳號</a>協助服務或洽談串接事宜。
    </CAlert>
    <div class="form-group">
      <label for="tspgEnable" class="d-block">啟用台新銀行付款</label>
      <CSwitch id="tspgEnable" size="sm" color="success" :checked.sync="MerchantData['tspgpay'].Enable" />
    </div>
    <template v-if="MerchantData['tspgpay'].Enable">
      <CInput id="tspgMerchantID" v-model="MerchantData['tspgpay'].MerchantID" size="sm" type="text">
        <template #label>
          <label for="tspgMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
        </template>
      </CInput>
      <CInput id="tspgHashKey" v-model="MerchantData['tspgpay'].HashKey" size="sm" type="text">
        <template #label>
          <label for="tspgHashKey" class="d-block">{{ $t('MerchantData.TerminalID') }}</label>
        </template>
      </CInput>
    </template>
  </div>
</template>

<script>
export default {
  name: "TSPGPAY",
  props: {
    MerchantData: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
