<template>
  <div>
    <CAlert class="d-flex justify-content-between" color="secondary" :fade="false">
      <div>
        <p class="mb-2">若申請過程若有任何問題，可洽詢TapPay服務專員</p>
        <p class="mb-0"><b>業務窗口</b>：Edwin Ko 柯禹彣</p>
        <p class="mb-0"><b>聯絡信箱</b>：edwinko@cherricorp.com</p>
        <p class="mb-0"><b>聯絡電話</b>：02-2366-0080 #125</p>
        <p class="mb-0"><b>LINE ID</b>：wenko2693279</p>
      </div>
      <div class="ButtonGroup">
        <CButton color="warning" target="_blank" href="https://page.line.me/umeans" class="font-weight-normal px-3 text-white mr-2" style="background-color: #f4a83b;border: 1px solid #f4a83b;">立即申請</CButton>
        <CButton color="info" target="_blank" href="https://www.tappaysdk.com/taiwan-zhtw" class="font-weight-normal px-3 text-white" style="background-color: rgb(115, 200, 209);border: 1px solid rgb(115, 200, 209);">瞭解服務</CButton>
      </div>
    </CAlert>
    <div class="form-group">
      <label for="tappayEnable" class="d-block">啟用TapPay付款</label>
      <CSwitch id="tappayEnable" size="sm" color="success" :checked.sync="MerchantData['tappay'].Enable" />
    </div>
    <template v-if="MerchantData['tappay'].Enable">
      <CInput id="tappayMerchantID" v-model="MerchantData['tappay'].MerchantID" size="sm" type="text">
        <template #label>
          <label for="tappayMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
        </template>
      </CInput>
      <CInput id="tappayMGID" description="可用於整合TapPay內所有的付款方式與通道，商戶號及MGID擇一填寫即可，若商戶號及MGID皆有填寫則系統會優先使用MGID進行付款。" v-model="MerchantData['tappay'].HashKey" size="sm" type="text">
        <template #label>
          <label for="tappayMGID" class="d-block">MGID</label>
        </template>
      </CInput>
      <CInput id="tappayAppID" v-model="MerchantData['tappay'].Account" size="sm" type="text">
        <template #label>
          <label for="tappayAppID" class="d-block">App ID</label>
        </template>
      </CInput>
      <CInput id="tappayAppKey" v-model="MerchantData['tappay'].Password" size="sm" type="text">
        <template #label>
          <label for="tappayAppKey" class="d-block">App Key</label>
        </template>
      </CInput>
      <CInput id="tappaySecureKey" v-model="MerchantData['tappay'].SecureKey" size="sm" type="text">
        <template #label>
          <label for="tappaySecureKey" class="d-block">{{ $t('MerchantData.SecureKey') }}</label>
        </template>
      </CInput>
      <CInput id="tappayApplePayMerchantID" v-model="MerchantData['tappay'].PublicKey" size="sm" type="text">
        <template #label>
          <label for="tappayApplePayMerchantID" class="d-block">Apple Pay MerchantID</label>
        </template>
      </CInput>
      <div class="form-group">
        <label for="tappayTestMode" class="d-block">{{ $t('MerchantData.TestMode') }}</label>
        <CSwitch id="tappayTestMode" size="sm" color="success" :checked.sync="MerchantData['tappay'].TestMode" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "TAPPAY",
  props: {
    MerchantData: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
