import { render, staticRenderFns } from "./FOCASPAY.vue?vue&type=template&id=09cb346b&scoped=true&"
import script from "./FOCASPAY.vue?vue&type=script&lang=js&"
export * from "./FOCASPAY.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09cb346b",
  null
  
)

export default component.exports