<template>
  <div>
    <div class="form-group">
      <label for="linepayEnable" class="d-block">啟用LINE Pay付款</label>
      <CSwitch id="linepayEnable" size="sm" color="success" :checked.sync="MerchantData['linepay'].Enable" />
    </div>
    <template v-if="MerchantData['linepay'].Enable">
      <CInput id="linepayMerID" v-model="MerchantData['linepay'].MerchantID" size="sm" type="text">
        <template #label>
          <label for="linepayMerID" class="d-block">Channel ID</label>
        </template>
      </CInput>
      <CInput id="linepayAccount" v-model="MerchantData['linepay'].SecureKey" size="sm" type="text">
        <template #label>
          <label for="linepayAccount" class="d-block">Channel Secret Key</label>
        </template>
      </CInput>
      <div class="form-group">
        <label for="linepayTestMode" class="d-block">{{ $t('MerchantData.TestMode') }}</label>
        <CSwitch id="linepayTestMode" size="sm" color="success" :checked.sync="MerchantData['linepay'].TestMode" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "LINEPAY",
  props: {
    MerchantData: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
