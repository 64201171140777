<template>
  <div>
    <div class="form-group">
      <label for="vi_momopayEnable" class="d-block">啟用MoMoPay</label>
      <CSwitch id="vi_momopayEnable" size="sm" color="success" :checked.sync="MerchantData['vi_momopay'].Enable" />
    </div>
    <template v-if="MerchantData['vi_momopay'].Enable">
      <CInput id="vi_momopayPaymentMerchantID" v-model="MerchantData['vi_momopay'].MerchantID" size="sm" type="text">
        <template #label>
          <label for="vi_momopayPaymentMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
        </template>
      </CInput>
      <CInput id="vi_momopayPaymentHashKey" v-model="MerchantData['vi_momopay'].HashKey" size="sm" type="text">
        <template #label>
          <label for="vi_momopayPaymentHashKey" class="d-block">{{ $t('MerchantData.HashKey') }}</label>
        </template>
      </CInput>
      <CInput id="vi_momopayPaymentHashIV" v-model="MerchantData['vi_momopay'].HashIV" size="sm" type="text">
        <template #label>
          <label for="vi_momopayPaymentHashIV" class="d-block">{{ $t('MerchantData.HashIV') }}</label>
        </template>
      </CInput>
      <div class="form-group">
        <label for="vi_momopayTestMode" class="d-block">{{ $t('MerchantData.TestMode') }}</label>
        <CSwitch id="vi_momopayTestMode" size="sm" color="success" :checked.sync="MerchantData['vi_momopay'].TestMode" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "VI_MOMOPAY",
  props: {
    MerchantData: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
